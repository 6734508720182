import styled from "styled-components"

export default styled.div`
  width: 100%;
  max-width: 40rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: 1.6rem;
    color: ${props => props.theme.colors.gray};

    &.approved {
      color: ${props => props.theme.colors.green};
    }

    &.reproved {
      color: ${props => props.theme.colors.red};
    }

    strong {
      margin-left: 1rem;
    }
  }

  .loadingLoader {
    color: ${props => props.theme.colors.gray};
  }
`
