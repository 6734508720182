import React, { useState, useEffect, useContext, useCallback } from "react"
import axios from "axios"

import { Store } from "store"
import { SET_TOKEN } from "store/reducers/tokenReducer"

export default function({ history }) {
  const [{ token }, dispatch] = useContext(Store)
  const [loaded, setLoaded] = useState(false)

  const claimInitialToken = useCallback(async () => {
    const res = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/token`,
      headers: {
        Authorization: "Bearer " + token.value,
        "Content-Type": "application/json"
      }
    })

    if (res.data) {
      setLoaded(true)
      dispatch({ type: SET_TOKEN, payload: res.data.token })
      return history.push("/liveness")
    }
  }, [dispatch, history, token])

  useEffect(() => {
    if (!loaded) claimInitialToken()
  }, [claimInitialToken, loaded])

  return <div />
}
