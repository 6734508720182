import React, { useState, useRef, useEffect, useCallback, useContext } from "react"
import Webcam from "react-webcam"
import CircularProgress from "@material-ui/core/CircularProgress"

import Container from "./styles"
import { getFullFaceDescription, loadModels } from "api/face"
import { image64toCanvasRef } from "utils/imageFns"
import { Store } from "store"
import { postFile } from "api/api"

const faceBounds = 264
const messagesArray = [
  {
    message: "",
    delay: 1
  },
  {
    message: "Sorria por 3 segundos AGORA",
    delay: 3
  },
  {
    message: "Feche um dos olhos por 3 segundos AGORA",
    delay: 3
  }
]
const framesArray = [1, 3, 3]
const totalFrames = messagesArray.length

export default function({ history }) {
  const cameraRef = useRef()
  const canvasRef = useRef(null)
  const [detectMessage, setDetectMessage] = useState("Carregando sistema de detecção facial")
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [modelsLoaded, setModelsLoaded] = useState(false)
  const [frameIndex, setFrameIndex] = useState(0)
  const [state] = useContext(Store)
  const { token } = state
  // const imgRef1 = useRef()
  // const imgRef2 = useRef()
  // const imgRef3 = useRef()

  const getFrame = useCallback(
    async (screenshot, face_box) => {
      let formData = new FormData()
      let file = null

      await image64toCanvasRef(canvasRef.current, screenshot, face_box)
      const croppedImage = canvasRef.current.toDataURL("image/jpeg", 0.9)

      // TESTE - REMOVER
      // if (frameIndex + 1 === 1) imgRef1.current.src = croppedImage
      // if (frameIndex + 1 === 2) imgRef2.current.src = croppedImage
      // if (frameIndex + 1 === 3) imgRef3.current.src = croppedImage
      // ---------------

      await fetch(croppedImage)
        .then(res => res.blob())
        .then(blob => {
          file = new File([blob], `liveness_${frameIndex}.jpeg`, { type: "image/jpeg" })
          formData.append("file", file)
        })

      const percentage = ((frameIndex + 1) * 100) / totalFrames
      setProgress(percentage)

      if (frameIndex + 1 === totalFrames) {
        setDetectMessage("")
        setLoading(true)

        const res = await postFile({
          url: "/frame",
          token: token.value,
          data: formData,
          history
        })

        if (res) {
          history.push("/evaluation")
        }
      } else {
        postFile({
          url: "/frame",
          token: token.value,
          data: formData,
          history
        })
      }
    },
    [frameIndex, history, token.value]
  )

  const findFace = useCallback(async () => {
    return new Promise(async resolve => {
      const screenshot = cameraRef.current.getScreenshot()
      await getFullFaceDescription(screenshot).then(async fullDesc => {
        if (fullDesc) {
          getFrame(screenshot, fullDesc._box)
          setFrameIndex(frameIndex + 1)
          resolve(true)
        }
        resolve(false)
      })
    })
  }, [frameIndex, getFrame])

  const main = useCallback(async () => {
    if (frameIndex <= totalFrames) {
      const screenshot = cameraRef.current.getScreenshot()

      if (screenshot) {
        await getFullFaceDescription(screenshot).then(async fullDesc => {
          if (fullDesc) {
            const face_box = fullDesc._box
            if (face_box._width < faceBounds && face_box._height < faceBounds) {
              setDetectMessage("Aproxime seu rosto...")
              setTimeout(main, 1000)
            } else {
              setDetectMessage("")

              if (frameIndex < totalFrames) {
                const currentMessage = messagesArray[frameIndex]
                const currentFrameDelay = framesArray[frameIndex]

                setDetectMessage(currentMessage.message)

                if (!currentMessage.message) {
                  setTimeout(() => {
                    setFrameIndex(frameIndex + 1)
                  }, currentMessage.delay * 1000)
                }

                setTimeout(async () => {
                  let descFound = false

                  while (!descFound) {
                    descFound = await findFace()
                  }
                }, currentFrameDelay * 1000)
              }
            }
          } else {
            setDetectMessage("Aguarde, detectando seu rosto...")
            setTimeout(main, 1000)
          }
        })
      } else {
        setDetectMessage("Aguarde, detectando seu rosto...")
        setTimeout(main, 1000)
      }
    }
  }, [findFace, frameIndex])

  useEffect(() => {
    if (!modelsLoaded) {
      async function fetchModels() {
        await loadModels()
        setDetectMessage("Aguarde, detectando seu rosto...")
        setModelsLoaded(true)
      }
      fetchModels()
    }
  }, [history, modelsLoaded, token])

  useEffect(() => {
    main()
  }, [main])

  const videoConstraints = {
    facingMode: "user"
  }

  return (
    <Container>
      <div className="cameraContainer">
        <div className={`cameraOverlay ${!detectMessage && "off"}`}>
          <span>{detectMessage}</span>
        </div>
        <div className={`loadingOverlay ${loading && "on"}`}>
          <CircularProgress className="loadingLoader" />
        </div>
        <div className="progress" style={{ width: `${progress}%` }} />
        <Webcam audio={false} ref={cameraRef} minScreenshotHeight={700} screenshotFormat="image/jpeg" videoConstraints={videoConstraints} />
        <canvas ref={canvasRef} id="canvas" className="hidden"></canvas>
      </div>

      {/* TESTE - REMOVER
      <div className="teste">
        <div>
          <p>frame 1</p>
          <img ref={imgRef1} alt="" />
        </div>
        <div>
          <p>frame 2</p>
          <img ref={imgRef2} alt="" />
        </div>
        <div>
          <p>frame 3</p>
          <img ref={imgRef3} alt="" />
        </div>
      </div>
       */}
    </Container>
  )
}
