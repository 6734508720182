import React, { useContext, useEffect, useCallback, useState } from "react"
import axios from "axios"
import CircularProgress from "@material-ui/core/CircularProgress"

import Container from "./styles"
import { Store } from "store"
import { Button } from "components/Button"

export default ({ history }) => {
  const [state] = useContext(Store)
  const { token } = state
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState(null)
  const [error, setError] = useState("")

  const getEvaluation = useCallback(async () => {
    setLoading(true)

    try {
      const res = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/evaluate`,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token.value
        }
      })

      if (res.status !== 200) {
        if (res.status > 400) {
          setError("Houve um erro ao processar os resultados")
          setLoading(false)
        } else {
          setTimeout(getEvaluation, 1000)
        }
      } else {
        setResult(res.data)
        setLoading(false)
      }
    } catch (e) {
      setError("Houve um erro ao processar os resultados")
      setLoading(false)
    }
  }, [token.value])

  useEffect(() => {
    getEvaluation()
  }, [getEvaluation])

  const handleRestart = () => {
    history.replace("/token")
  }

  return (
    <React.Fragment>
      <h1>Resultado</h1>

      <Container>
        {loading && (
          <React.Fragment>
            <p>Aguarde, calculando score...</p>
            <CircularProgress className="loadingLoader" />
          </React.Fragment>
        )}
        {!loading && result && (
          <React.Fragment>
            <p className={result.approved ? "approved" : "reproved"}>{result.approved ? "Aprovado" : "Reprovado"}</p>
            <p>
              Score:
              <strong>{result.score}</strong>
            </p>
            <Button onClick={handleRestart}>Reiniciar</Button>
          </React.Fragment>
        )}
        {!loading && error && (
          <React.Fragment>
            <p className="reproved">{error}</p>
            <Button onClick={handleRestart}>Reiniciar</Button>
          </React.Fragment>
        )}
      </Container>
    </React.Fragment>
  )
}
