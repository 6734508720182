import React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"

import SecuredRoute from "./SecuredRoute"

import DefaultContainer from "containers/Default"
import LivenessContainer from "containers/Liveness"

import Login from "pages/Login"
import Token from "pages/Token"
import Liveness from "pages/Liveness"
import Evaluation from "pages/Evaluation"

export default () => {
  return (
    <Router>
      <Route
        render={({ location }) => (
          <div className="content">
            <Switch location={location}>
              <DefaultContainer exact path="/" component={Login} />
              <SecuredRoute path="/token" parent={DefaultContainer} child={Token} />
              <SecuredRoute path="/liveness" parent={LivenessContainer} child={Liveness} />
              <SecuredRoute path="/evaluation" parent={DefaultContainer} child={Evaluation} />
            </Switch>
          </div>
        )}
      />
    </Router>
  )
}
