export const SET_TOKEN = "SET_TOKEN"
export const SET_FIELD = "SET_FIELD"

export default (state, { type, payload }) => {
  switch (type) {
    case SET_TOKEN:
      return {
        ...state,
        password: "",
        value: payload
      }
    case SET_FIELD:
      return {
        ...state,
        [payload.field]: payload.value
      }
    default:
      return state
  }
}
