import React, { useReducer } from "react"
import { ThemeProvider } from "styled-components"
import { ThemeProvider as MaterialProvider } from "@material-ui/styles"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import defaultTheme, { materialTheme } from "themes/default"
import { Store, initialState } from "store"
import rootReducer from "store/reducers/rootReducer"
import Routes from "config/routes"

function App() {
  const store = useReducer(rootReducer, initialState)

  return (
    <Store.Provider value={store}>
      <ThemeProvider theme={defaultTheme}>
        <MaterialProvider theme={materialTheme}>
          <ToastContainer />
          <Routes />
        </MaterialProvider>
      </ThemeProvider>
    </Store.Provider>
  )
}

export default App
