import styled from "styled-components"

export default styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .cameraContainer {
    width: 100vw;
    height: 100vh;
  }

  .cameraOverlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 3rem;
    color: white;

    &.off {
      display: none;
    }

    span {
      width: 90%;
    }
  }

  .loadingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.6);
    width: 100%;
    height: 100%;
    z-index: 10;
    display: none;
    justify-content: center;
    align-items: center;
    text-align: center;

    &.on {
      display: flex;
    }
  }

  .loadingLoader {
    color: ${props => props.theme.colors.gray};
  }

  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    transform: scaleX(-1);
  }

  .progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3rem;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    z-index: 10;
    transition: width 0.2s ease-in;
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    .cameraContainer {
      width: 45rem;
      height: 45rem;
      position: relative;
      border-radius: 3rem;
    }

    video {
      border-radius: 3rem;
    }

    .cameraOverlay {
      border-radius: 3rem;
      font-size: 2.5rem;
    }
  }

  .hidden {
    display: none;
  }

  .teste {
    display: flex;
  }
`
